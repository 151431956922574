import React, { useEffect } from 'react'
import { Box, Text, Heading } from 'theme-ui'
import { gsap } from "gsap"




const HighGrounds = ({ 
    highGroundsTitle,
    highGroundsDescription,
 }) => {

    return (
        <Box
            sx={{
                mt: [5,5, 0],
                width: "100%",
                height: ['100%','100%', "100vh"],
                display: 'flex',
                position: 'relative',
            }}>

            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>
                <Heading className="splittext"
                    sx={{
                        width: '80%',
                        maxWidth: 700,
                        fontSize: [6, 8, 7],
                        color: 'white',
                        pb: 4,
                    }}>
                    {highGroundsTitle}
                </Heading>

                <Text className="splittext"
                    sx={{

                        width: ['80%','80%', '55%'],
                        maxWidth: ['100%','100%',430],
                        fontSize: [4, 6, 5],
                        color: 'white',
                        textAlign: ['center', 'inherit'],
                        mb: [4,0, 0],
                    }}>
                    {highGroundsDescription}
                </Text>

            </Box>

            <Box sx={{
                position: 'absolute',
                left: 0,
                // botom: '14vw',
                bottom: '-5vw',
                height: '100%',
                width: '100%',
                opacity: 0.3,
            }}>
                <svg id="izohipse" viewBox="0 0 1440 803" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M514.833 558.601C518.167 567.217 529.071 571.381 534.107 572.387C559.284 570.232 614.222 566.01 632.558 566.355C655.478 566.786 647.143 568.94 656.519 576.694C665.896 584.449 704.964 581.433 725.279 583.587C745.594 585.741 806.019 577.987 823.73 568.94C841.441 559.893 858.631 558.601 872.695 551.277C886.76 543.953 905.512 539.646 909.159 537.061C912.805 534.476 945.101 524.568 968.542 522.414C991.983 520.26 1005.53 537.061 1019.07 544.815C1032.61 552.569 1052.93 543.092 1068.03 539.646C1083.14 536.199 1095.64 524.137 1092.52 511.213C1089.39 498.289 1071.68 492.689 1058.14 487.519C1044.59 482.35 961.249 484.934 909.159 495.704C857.068 506.474 849.775 523.706 833.627 528.014C817.479 532.322 791.955 543.092 754.45 528.014C716.945 512.936 690.378 521.121 666.417 513.798C642.455 506.474 616.41 512.506 596.094 513.798C575.779 515.09 555.985 529.737 539.316 537.061C522.647 544.385 510.666 547.831 514.833 558.601Z" stroke="white" />
                    <path d="M496 526.95C462 550.55 467.167 570.117 474 576.95C496.5 611.451 533.5 599.95 553 600.95C572.5 601.95 589 617.95 604 621.45C619 624.95 628.5 620.95 642 621.45C655.5 621.95 655 626.45 678 625.45C701 624.45 733 611.45 755 605.95C777 600.45 821.5 584.45 861 580.45C900.5 576.45 954 584.95 976.5 587.45C999 589.95 1016.5 599.95 1035 605.95C1053.5 611.95 1095 600.95 1110 596.95C1125 592.95 1135 580.45 1143 565.95C1151 551.45 1150 535.95 1153.5 521.45C1157 506.95 1138.5 499.95 1129 485.95C1119.5 471.95 1080 438.451 1060 425.451C1040 412.451 1001.5 419.451 943 425.451C884.5 431.451 838 485.451 800.5 488.951C763 492.451 746.5 481.451 733 476.95C719.5 472.45 702 476.95 678 481.451C654 485.951 643.5 477.95 628.5 476.95C613.5 475.95 601.5 485.95 585 491.95C568.5 497.95 538.5 497.45 496 526.95Z" stroke="white" />
                    <path d="M164.094 204.299C162.045 211.514 154.04 216.453 142.444 218.452C130.888 220.445 115.943 219.478 100.361 215.052C83.2471 210.191 71.2819 198.174 60.4476 186.347C58.7149 184.455 56.7556 182.43 54.7348 180.341C51.5823 177.083 48.2802 173.669 45.4558 170.368C43.1463 167.669 41.1905 165.082 39.9316 162.766C38.661 160.429 38.1606 158.477 38.5818 156.994C39.5712 153.511 42.7611 151.418 47.7181 150.439C52.6677 149.461 59.2351 149.628 66.6929 150.548C81.5991 152.387 99.8905 157.207 115.53 161.649C121.597 163.373 126.151 166.289 130.244 169.516C131.854 170.786 133.387 172.099 134.917 173.409C135.336 173.768 135.755 174.127 136.175 174.484C138.124 176.143 140.094 177.772 142.21 179.246C142.942 179.756 143.67 180.261 144.391 180.761C150.048 184.686 155.283 188.317 159 191.953C163.195 196.058 165.309 200.023 164.094 204.299Z" stroke="white" />
                    <path d="M139.471 194.684C138.283 198.864 133.658 201.79 126.838 202.994C120.055 204.191 111.273 203.643 102.112 201.041C92.0864 198.193 85.0817 191.106 78.7086 184.086C77.681 182.954 76.525 181.749 75.3351 180.509C73.4853 178.58 71.5534 176.566 69.8945 174.609C68.5395 173.011 67.4007 171.488 66.6719 170.131C65.9317 168.753 65.6726 167.653 65.9011 166.849C66.4598 164.882 68.2512 163.661 71.1456 163.077C74.0341 162.495 77.8815 162.581 82.2723 163.116C91.0444 164.184 101.813 167.019 111.031 169.638C114.572 170.643 117.229 172.354 119.626 174.258C120.571 175.007 121.469 175.783 122.369 176.56C122.616 176.773 122.863 176.986 123.111 177.199C124.259 178.184 125.425 179.155 126.68 180.035C127.111 180.337 127.54 180.636 127.964 180.932C131.298 183.261 134.364 185.402 136.538 187.546C138.991 189.967 140.162 192.248 139.471 194.684Z" stroke="white" />
                    <path d="M301 212.95C262.5 215.45 236 231.45 231.5 257.45C223.971 300.95 268 267.95 304.5 283.45C341 298.95 309.5 328.95 368 368.45C426.5 407.95 447.5 395.45 457.5 385.45C467.5 375.45 463 340.95 454.5 328.95C446 316.95 450.5 317.45 447.5 291.95C444.5 266.45 415.5 257.45 386.5 248.45C357.5 239.45 339.5 210.45 301 212.95Z" stroke="white" />
                    <path d="M308.522 225.795C274.164 225.595 259.985 230.995 255 248.45C246.5 273.9 277.08 261.561 308.979 273.9C340.878 286.24 337.687 289.374 381 328.95C395.775 342.45 409.711 373.87 430 364.45C444 357.95 443.034 323.528 435.922 314.95C428.81 306.371 409.708 302.935 408 285.45C406.291 267.964 393.969 264 368.5 256.45C343.03 248.9 342.879 225.995 308.522 225.795Z" stroke="white" />
                    <path d="M321.5 243.45C308.5 237.45 295.5 241.95 280 247.45C278.5 258.45 303.565 259.669 307.565 259.169C311.565 258.669 320.565 262.169 327.065 269.169C333.565 276.169 354.065 284.169 371.065 289.669C388.065 295.169 395.065 326.669 401.065 333.669C407.065 340.669 417 333.95 422.5 329.45C428 324.95 413.065 315.669 407.065 312.669C401.065 309.669 396.565 287.169 393.065 280.669C389.565 274.169 374.565 270.669 362.065 270.169C349.565 269.669 334.5 249.45 321.5 243.45Z" stroke="white" />
                    <path d="M112.5 489.45C102 481.45 78.5 467.45 64 489.45C64 505.95 78.5 515.45 94.5 521.45C110.5 527.45 112.5 553.45 133 572.45C153.5 591.45 173 579.95 192.5 578.95C212 577.95 237 587.95 250.5 593.45C264 598.95 303.5 630.95 338 635.45C372.5 639.95 399.5 631.95 405.5 626.45C411.5 620.95 410.5 610.45 412 602.95C413.5 595.45 418.5 601.95 426.5 583.45C434.5 564.95 410 548.95 384 528.45C358 507.95 319 534.45 290 532.95C261 531.45 234.5 482.95 219 477.95C203.5 472.95 170 474.95 158 489.45C146 503.95 123 497.45 112.5 489.45Z" stroke="white" />
                    <path d="M132.5 510.95C118.32 504.69 90 487.151 90 500.45C90 513.748 116.078 519.114 129 523.95C141.922 528.786 139.444 546.136 156 561.45C172.556 576.764 195.252 566.756 211 565.95C226.748 565.144 250.597 580.517 261.5 584.95C272.403 589.383 301.138 611.322 329 614.949C356.862 618.576 377.154 610.383 382 605.95C386.846 601.517 376.789 595.494 378 589.449C379.211 583.404 388.539 593.36 395 578.449C401.461 563.538 398.998 562.472 378 545.949C357.002 529.426 304.92 544.658 281.5 543.449C258.08 542.24 228.518 502.98 216 498.95C203.482 494.92 183.191 494.262 173.5 505.95C163.809 517.637 147.225 517.45 132.5 510.95Z" stroke="white" />
                    <path d="M217.5 527.45C200.5 519.45 154 519.45 154 535.95C154 550.95 194 547.45 206.5 547.45C216.5 547.45 248.5 562.95 269.5 575.45C290.5 587.95 320 593.95 329 592.45C338 590.95 348.5 575.45 348.5 562.95C348.5 550.45 308.5 550.95 286.5 553.95C264.5 556.95 234.5 535.45 217.5 527.45Z" stroke="white" />
                    <path d="M169.5 285.45C140.5 268.95 87.0002 294.95 77.5002 317.95C71.0002 350.45 88.5002 345.45 110 367.45C131.5 389.45 171.5 358.45 195 381.95C218.5 405.45 234.786 446.163 253 446.95C276.611 447.97 282 461.45 298.5 478.45C315 495.45 352 482.45 364 469.45C376 456.45 361.5 447.95 352 430.95C342.5 413.95 355.5 408.45 324.5 375.45C293.5 342.45 239.5 348.95 224.5 339.95C209.5 330.95 198.5 301.95 169.5 285.45Z" stroke="white" />
                    <path d="M171.026 320.295C147.526 291.295 141.026 327.295 115.026 327.295C89.0265 327.295 107.496 342.7 122.526 348.295C166.479 350.625 187.526 356.295 208.526 365.295C229.526 374.295 243.027 419.295 258.026 419.295C273.026 419.295 301.081 469.625 321.526 462.795C330.335 459.067 319.905 442.299 321.526 423.795C324.494 389.783 308.526 384.295 282.026 377.795C225.026 357.795 194.526 349.294 171.026 320.295Z" stroke="white" />
                    <path d="M404 451.95C395.2 464.35 403.667 476.117 409 480.45C419.307 490.823 442.648 502.232 452 494.95C462.058 487.119 458.724 467.01 465 461.95C469.439 458.371 499.986 450.372 490 446.45C482.255 443.408 456.815 440.102 452 431.45C446.261 421.139 415 436.45 404 451.95Z" stroke="white" />
                    <path d="M414.849 467.082C419.349 482.582 434.349 488.082 441.349 480.082C448.349 472.082 455.349 466.082 448.349 461.582C441.349 457.082 461.349 451.582 457.349 446.082C453.349 440.582 439.331 440.697 433.849 446.082C424.763 456.257 410.349 451.582 414.849 467.082Z" stroke="white" />
                    <path d="M584 728.95C529.6 703.75 508.5 688.45 515 674.45C513.834 664.283 526 650.05 584 674.45C656.5 704.95 768.5 649.95 852.5 642.95C936.5 635.95 1086.5 637.45 1288.5 582.95C1450.1 539.35 1365.83 486.783 1303.5 465.95C1266.33 434.617 1245.3 345.45 1458.5 239.45C1671.7 133.45 1547.33 219.617 1458.5 275.95C1445 279.283 1400.7 298.15 1331.5 346.95C1245 407.95 1344 463.95 1394 498.95C1444 533.95 1381.5 690.45 1288.5 649.45C1195.5 608.45 904.5 655.45 832 690.45C759.5 725.45 652 760.45 584 728.95Z" stroke="white" />
                    <path d="M1017.5 319.95C1003 339.95 1013.5 359.95 1032 379.95C1050.5 398.951 1084.5 400.95 1101 400.45C1117.5 399.95 1133 422.95 1161.5 430.45C1190 437.95 1213.5 406.45 1235 388.95C1256.5 371.45 1264.5 336.95 1249 310.95C1233.5 284.95 1203 293.95 1193 297.45C1183 300.95 1135.5 319.95 1105 293.95C1074.5 267.95 1032 299.95 1017.5 319.95Z" stroke="white" />
                    <path d="M1065.07 310C1044.07 310 1031.07 336.5 1034.57 354C1045.07 381 1083.57 373.5 1104.07 373C1124.57 372.5 1164.57 407 1183.57 399.5C1202.57 392 1234.07 348 1222.07 327C1210.07 306 1174.07 327.5 1138.57 332C1103.07 336.5 1086.07 310 1065.07 310Z" stroke="white" />
                    <path d="M1102.38 338.041C1089.38 338.541 1060.88 342.041 1072.88 352.541C1084.55 355.375 1110.08 360.941 1118.88 360.541C1129.88 360.041 1158.38 381.541 1168.38 381.541C1178.38 381.541 1168.38 363.541 1154.88 352.541C1141.38 341.541 1115.38 337.541 1102.38 338.041Z" stroke="white" />
                    <path d="M1151 231.45C1155 251.45 1158.5 251.95 1201.5 252.95C1208.5 256.284 1224.9 263.35 1234.5 264.95C1246.5 266.95 1256 271.45 1299.5 277.95C1343 284.45 1332 261.45 1348 249.95C1364 238.45 1380.5 221.95 1394.5 195.95C1408.5 169.95 1401 161.95 1402.5 128.45C1404 94.9502 1401 97.9502 1390.5 73.4502C1380 48.9502 1360 46.9504 1322.5 22.9504C1285 -1.04959 1258.5 -0.549587 1222.5 2.45041C1186.5 5.45041 1175 26.9504 1160 41.9504C1145 56.9504 1151 55.9504 1135 86.9504C1119 117.95 1141 106.45 1160 125.45C1179 144.45 1168 154.95 1160 176.45C1152 197.95 1147 211.45 1151 231.45Z" stroke="white" />
                    <path d="M1195.5 142.95C1189.5 165.95 1192.5 179.95 1208 199.95C1203.83 201.783 1198 207.45 1208 215.45C1220.5 225.45 1259 226.45 1283.5 223.45C1308 220.45 1364 173.45 1368 151.95C1372 130.45 1362.5 120.45 1354.5 100.45C1346.5 80.4501 1317.5 60.4501 1263.5 35.4501C1209.5 10.4501 1244 33.9501 1234.5 35.4501C1225 36.9501 1226.5 38.4501 1222.5 40.9501C1218.5 43.4501 1212 53.9501 1208 63.9501C1204 73.9501 1211 85.4501 1208 100.45C1205 115.45 1201.5 119.95 1195.5 142.95Z" stroke="white" />
                    <path d="M107 642.95C74 621.45 55 660.95 49.5 664.45C57.5 685.95 72 684.95 85.5 701.95C99 718.95 116 720.95 126.5 714.45C137 707.95 163.5 692.45 159 676.95C154.5 661.45 140 664.45 107 642.95Z" stroke="white" />
                    <path d="M78 129.95C53.2 131.15 15.6667 147.117 3.24249e-05 154.95C-10.5 162.451 -18 156.95 -17 169.95C-16 182.95 4.50003 194.95 19 198.45C33.5 201.95 29 211.95 60.5 231.95C92 251.95 127 251.95 168 236.95C209 221.95 215.5 203.45 221 177.95C226.5 152.45 183.5 156.45 178 159.95C172.5 163.45 160.5 161.45 148 142.95C135.5 124.45 109 128.45 78 129.95Z" stroke="white" />
                    <path d="M-6 653.95C12.6667 641.617 64.1 619.45 120.5 629.45C191 641.95 203.5 693.95 234 699.45C264.5 704.95 440 666.95 474 689.45C508 711.95 595 833.95 716.5 793.45C838 752.95 958 671.95 1013.5 670.95C1069 669.95 1306 697.95 1345.5 670.95C1385 643.95 1447.5 535.95 1414.5 496.45C1381.5 456.95 1364 420.95 1382.5 392.45C1401 363.95 1432.5 350.95 1458.5 343.95" stroke="white" />
                    <path d="M22.5 352.45C4.1 337.65 -7.33351 355.951 -13.0002 365.451C-20.0002 385.451 -32.8002 429.151 -28.0002 443.951C-22.0002 462.451 1.49982 439.951 15.4998 430.951C29.4998 421.951 75.5 419.951 100.5 430.951C125.5 441.951 162.5 445.586 162.5 421.451C162.5 412.951 124.5 393.951 86.9998 396.951C49.4998 399.951 45.5 370.95 22.5 352.45Z" stroke="white" />
                    <path d="M24.7144 377.107C9.82544 368.724 0.573635 379.09 -4.01173 384.471C-9.67601 395.798 -20.0336 420.55 -16.1495 428.932C-11.2944 439.41 7.72142 426.667 19.05 421.569C30.3785 416.472 67.6011 415.339 87.8307 421.569C108.06 427.799 138 429.858 138 416.188C138 411.374 107.251 400.613 76.9066 402.312C46.5622 404.011 43.3256 387.585 24.7144 377.107Z" stroke="white" />
                </svg>

            </Box>
        </Box>


    )
}

export default HighGrounds

