import React, {useContext} from 'react'
import { Box, Heading, Button, Text } from 'theme-ui'
import Img from 'gatsby-image'
import {Link} from 'gatsby'
import LangContext from "./Context/LangContext"
import {
    handleFarmBoxButtonLabel,
    handleLinkLanguage
  } from "./helpers"

const Farmbox = ({ 
    farmTitle,
    farmImage,
    farmDescription,
}) => {
    // console.log(data)
    const { currentLang } = useContext(LangContext)

    
      
    return (


        <Box
            sx={{
                position: 'relative',
                width: ['100%',],
                height: ['100vh'],
                display: 'flex',
                overflow: 'hidden',
                margin: 'auto',
                // mb: [4,4,6],
            }}>


            {/* // slika  */}


            <Box
                className="farmBg"
                sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    zIndex: 0,
                    opacity: 0.3,
                    overflow: 'hidden',
                    '& .gatsby-image-wrapper': {
                        height: '100%',
                    }

                }}>
                <Img fluid={farmImage.fluid}>

                </Img>

            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: ['center', 'left'],
                    justifyContent: ['center','flex-end'],
                    alignItems: ['center', 'flex-start'],
                    position: 'relative',
                    zIndex: 1,

                    padding: 5,
                    width: '100%',
                    height: '100%',

                }}>
                {/* slika krune */}

                <Box sx={{
                    mb: 3,
                }}>
                    <svg id="farmBoxCrown" width="76" height="41" viewBox="0 0 76 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.75342 39.5336C1.75342 39.5336 11.7441 20.8093 38.148 20.8093C64.5518 20.8093 74.5425 39.5336 74.5425 39.5336" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M28.8711 21.5295L36.0073 12.1674C37.4345 10.7271 39.5754 10.7271 41.0026 12.1674L47.4252 21.5295" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M38.1477 10.007C40.5124 10.007 42.4294 8.07239 42.4294 5.68598C42.4294 3.29956 40.5124 1.36499 38.1477 1.36499C35.783 1.36499 33.866 3.29956 33.866 5.68598C33.866 8.07239 35.783 10.007 38.1477 10.007Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M54.5612 20.0892C56.926 20.0892 58.843 18.1547 58.843 15.7682C58.843 13.3818 56.926 11.4473 54.5612 11.4473C52.1965 11.4473 50.2795 13.3818 50.2795 15.7682C50.2795 18.1547 52.1965 20.0892 54.5612 20.0892Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M21.7346 20.0892C24.0993 20.0892 26.0163 18.1547 26.0163 15.7682C26.0163 13.3818 24.0993 11.4473 21.7346 11.4473C19.3699 11.4473 17.4529 13.3818 17.4529 15.7682C17.4529 18.1547 19.3699 20.0892 21.7346 20.0892Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M64.5517 25.8504C66.1282 25.8504 67.4062 24.5607 67.4062 22.9698C67.4062 21.3788 66.1282 20.0891 64.5517 20.0891C62.9753 20.0891 61.6973 21.3788 61.6973 22.9698C61.6973 24.5607 62.9753 25.8504 64.5517 25.8504Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M11.7441 25.8504C13.3206 25.8504 14.5986 24.5607 14.5986 22.9698C14.5986 21.3788 13.3206 20.0891 11.7441 20.0891C10.1676 20.0891 8.88965 21.3788 8.88965 22.9698C8.88965 24.5607 10.1676 25.8504 11.7441 25.8504Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
                        <path d="M74.5425 39.5334C74.5425 39.5334 64.5518 25.8503 38.148 25.8503C11.7441 25.8503 1.75342 39.5334 1.75342 39.5334" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
                    </svg>

                </Box>
                {/* Heading */}
                <Heading
                id="farmBoxHeading"
                    sx={{
                        fontSize: [6,8,7,96],
                        color: 'white',
                        // width: 390,
                        mb: 2,

                    }}>
                    {farmTitle}
                </Heading>

                <Text
                id="farmBoxDescription"
                    sx={{
                        fontSize: [4,6,5],
                        color: 'white',

                    }}>
                    {farmDescription}
                </Text>
                <Link id="farmBoxButton" to={handleLinkLanguage('/farm', currentLang)}>
                <Button
                    sx={{
                        mt: '35px',
                    }}>

                    {handleFarmBoxButtonLabel(currentLang)}

                </Button>
                </Link>
            </Box>
        </Box>











    )
}
export default Farmbox