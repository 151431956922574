import React, { useRef, useEffect, useContext, useState } from "react"
import { Box, Heading, Button, Text, jsx } from "theme-ui"
import Img from "gatsby-image"
import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"
import MorphSVGPlugin from 'gsap/MorphSVGPlugin'
import { Link } from "gatsby"
import LangContext from "./Context/LangContext"
import {
  handleLinkLanguage,
  handleBlueberryLabel,
  handleWholesaleLabel,
} from "./helpers"
import { keyframes } from "@emotion/core"
/** @jsx jsx */

const pulseRing = keyframes`
        0% {
            transform: scale(.33);
        }
        80%, 100% {
            opacity: 0;
        }
    `
const pulseDot = keyframes`
    0% {
        transform: scale(.95);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(.95);
    }
`

const Hero = ({ title, subtitle, heroBackgroundImage, heroSeasonNews }) => {
  const { setPage, currentPage, setLang, currentLang } = useContext(LangContext)
  const [isNewsVisible, toggleNewsVisible] = useState(false)

  let heroBg = useRef(null)
  let headingRef = useRef(null)
  let subtitleRef = useRef(null)
  let buttonRef = useRef([])
  buttonRef.current = []
  let zvezdicaRef = useRef(null)

  useEffect(() => {
    gsap.registerPlugin(SplitText, MorphSVGPlugin)
    var tlHero = gsap.timeline({ paused: true })
    new SplitText(headingRef.current, {
      type: "lines",
      linesClass: `lineChild`,
    })
    new SplitText(headingRef.current, {
      type: "lines",
      linesClass: "o-hidden",
    })
    tlHero.fromTo(
      heroBg.current,
      { scale: 1.3, autoAlpha: 0 },
      { scale: 1.2, duration: 4, autoAlpha: 1 }
    )
    tlHero.from(headingRef.current, { autoAlpha: 0 }, "<0.5")
    tlHero.from(
      `.lineChild`,
      {
        opacity: 0,
        y: 30,
        ease: "expo",
        stagger: 0.1,
        duration: 1.2,
        autoAlpha: 0,
      },
      "<1"
    )
    tlHero.from(
     '.splittextsubtitle',
      { opacity: 0, duration: 1, autoAlpha: 0, stagger: 0.1 },
      ">-0.5"
    )
    tlHero.from(
      buttonRef.current,
      { opacity: 0, stagger: 0.1, autoAlpha: 0 },
      "<0.5"
    )

    tlHero.from(
      zvezdicaRef.current,
      { x: "-100%", rotate: -480, opacity: 0, autoAlpha: 0 },
      "<0.5"
    )
    // tlHero.from('#updateBox', {opacity: 0})
    tlHero.delay(0).play()

    // morphTl.play()
    tl.current.to('#newsIcon', {
      duration: 0.25,
      morphSVG: "#closeIcon",
  })
  }, [])

  useEffect(() => {
    
  isNewsVisible ? tl.current.play() : tl.current.reverse()
  }, [isNewsVisible])

  const tl = useRef(gsap.timeline({ paused: true }));
  

  

  // const handlePlayMorph = () => {
  //   console.log('working Play', morphTl)
  //   morphTl.play()
  //   console.log('one')
  // }
  // const handleReverseMorph = () => morphTl.reverse()

  


    

  const handleBenefits = () => subtitle.map(y => <Text
    ref={subtitleRef}
    className="splittextsubtitle"
    sx={{
      // visibility: 'hidden',
      fontSize: [3, 6, 3],
      color: "white",
      mb: 1,
    }}
  >
    {/* {subtitle} */}
    {y.benefit}
  </Text>)



  return (
    <Box
      sx={{
        width: "100%",
        height: ["100vh", "100vh", "100vh"],
        position: "relative",
        display: "flex",
        flexDirection: ["column", "column", "row"],
        overflow: "hidden",
      }}
    >
      {/* slika */}
      <Box
        ref={heroBg}
        className="hero__bg-cont"
        sx={{
          visibility: "hidden",
          position: "absolute",
          width: "100%",
          height: ["100vh", "100vh", "100%"],
          left: 0,
          top: 0,
          zIndex: 0,
          "& .gatsby-image-wrapper": {
            height: "100%",
            opacity: 0.2,
          },
        }}
      >
        <Img fluid={heroBackgroundImage.fluid}></Img>
      </Box>
      {/* Heading */}
      <Box
        sx={{
          width: ["100%", "95%", "70%"],
          display: "flex",
          flexDirection: "column",
          justifyContent: ["center", "center", "flex-end"],
          height: ["100vh", "100%"],
          alignItems: ["center", "start"],
          textAlign: ["center", "left"],
          px: [3,5],
          py: [3,5],
          pt: [4,5],
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: 5,
            bottom: 5,
            width: [75],
            height: 75,
            borderRadius: "50%",
            display: "flex",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            zIndex: 2,
            alignItems: ["flex-end", "flex-end", "flex-start"],
            justifyContent: "center",
            mb: [4, 4],
            height: [30, 50],
            width: [30, 50],
          }}
        >
          <svg
            ref={zvezdicaRef}
            className="hero-zvezdica"
            width="100%"
            height="100%"
            viewBox="0 0 89 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.1215 85.3961C18.8608 77.8558 24.4912 60.9655 23.955 58.251C23.4187 55.5364 17.2522 56.1397 17.2522 56.1397C14.3517 56.983 11.2853 56.7314 8.52432 55.4236C5.76331 54.1159 3.46049 51.8242 1.9698 48.901C-5.0011 35.0267 7.86825 22.359 19.6652 25.0735C19.6652 25.0735 14.8392 9.99285 23.955 3.35736C23.955 3.35736 37.0924 -8.40555 54.7878 12.1041L60.4181 17.5332C63.8632 14.3731 68.1721 12.6473 72.6172 12.6473C77.0623 12.6473 81.3713 14.3731 84.8163 17.5332C95.5408 29.2961 82.6714 45.2816 79.1859 47.6945C81.3947 48.846 83.3561 50.521 84.9358 52.6048C86.5154 54.6885 87.676 57.1318 88.3381 59.7671C89.0002 62.4025 89.148 65.1677 88.7715 67.8734C88.395 70.5791 87.5031 73.1612 86.1569 75.4429C85.1963 77.8203 83.7504 79.909 81.9368 81.539C80.1232 83.169 77.9932 84.2944 75.72 84.8234C73.4468 85.3525 71.0946 85.2704 68.855 84.5838C66.6153 83.8971 64.5514 82.6254 62.8312 80.8719C60.8774 78.6363 58.3422 77.1575 55.5921 76.6494C52.911 77.2526 42.7228 93.8413 30.1215 85.3961Z"
              fill="white"
            />
          </svg>
        </Box>
        <Heading
          ref={headingRef}
          className="splittextheading"
          sx={{
            visibility: "hidden",
            fontSize: [6, 8, 7, 8],
            width: ["100%", "80%", "75%", "100%"],
            color: "white",
            mb: 4,
          }}
        >
          {title}
        </Heading>
        {handleBenefits()}
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row"],
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: ["row", "row", "row"],
              mt: 4,
              // px: [3,0],
            }}
          >
            <Link to={handleLinkLanguage("/blueberry", currentLang)}>
              <Button
                ref={el => (buttonRef.current[0] = el)}
                className="herobutton"
                sx={{
                  // visibility: 'hidden',
                  mr: [1, 0, 3],
                  mb: [3, 3, 0],
                }}
              >
                {handleBlueberryLabel(currentLang)}
              </Button>
            </Link>
            <Link to={handleLinkLanguage("/wholesale", currentLang)}>
              <Button
                ref={el => (buttonRef.current[1] = el)}
                className="herobutton"
                sx={{
                  // visibility: 'hidden',
                  bg: "rgba(255,255,255,0)",
                  border: "1px solid white",
                  color: "white",
                  "&:hover": {
                    bg: "rgba(255,255,255, 0.1)",
                    // color: 'primaryBlue',
                  },
                }}
              >
                {handleWholesaleLabel(currentLang)}
              </Button>
            </Link>
          </Box>
        </Box>
        {/* krug sa logom */}
      </Box>

      <Box
        onClick={() => toggleNewsVisible(!isNewsVisible)}
        sx={{
          position: "fixed",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
          bg: "rgba(0,0,0,0.3)",
          zIndex: 3,
          display: isNewsVisible ? "flex" : "none",
        }}
      ></Box>
      <Box
        id="updateBox"
        onClick={() => {
          toggleNewsVisible(!isNewsVisible)
        }}
        sx={{
          width: 100,
          height: 100,
          position: ["relative", "absolute"],
          alignSelf: ["center", "auto"],
          right: ['auto', 50],
          top: ["auto", "50%"],
          transform: ["none", "translateY(-50%)"],
          flexShrink: ["0", "auto"],
          mb: [25, 0],
          //   bg: "secondaryBlue",
          borderRadius: "50%",
          zIndex: 5,
          display: ["none", "flex"],
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            cursor: "pointer",
          },
          "& svg": {
            opacity: 0.7,
          },
          "&:hover svg": {
            opacity: 1,
          },
        }}
      >
        <Box
          sx={{
            border: isNewsVisible ? "none" : "1px solid rgba(255,255,255,0.5)",
            animation: isNewsVisible
              ? "none"
              : `${pulseDot} 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite`,
            content: '""',
            position: "absolute",
            display: "block",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            top: "50%",
            left: "50%",
            marginLeft: "-50%",
            borderRadius: "100%",
            marginTop: "-50%",
          }}
        ></Box>
        <Box
          sx={{
            content: '""',
            position: "absolute",
            display: "block",
            width: ["150%", "200%"],
            height: ["150%", "200%"],
            boxSizing: "border-box",
            top: ["75%", "50%"],
            left: ["75%", "50%"],
            marginLeft: "-100%",
            marginTop: "-100%",
            borderRadius: "100%",
            // backgroundColor: '',
            border: isNewsVisible ? "none" : "1px solid rgba(255,255,255,1)",
            animation: isNewsVisible
              ? "none"
              : `${pulseRing} 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite`,
            zIndex: 10,
          }}
        ></Box>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50%"
          height="50%"
          fill="none"
          viewBox="0 0 32 32"
        >
          <path id="newsIcon" fill="#fff" fill-rule="evenodd" d="M16.5 22c4.694 0 8.5-4.5 8.5-6s-3.806-6-8.5-6S8 14.5 8 16s3.806 6 8.5 6zm0-3c1.76 0 3.188-1.343 3.188-3s-1.428-3-3.188-3c-1.76 0-3.188 1.343-3.188 3s1.428 3 3.188 3z" clip-rule="evenodd"/>
          {/* <path id="newsIcon" fill="#fff" d="M10 9.5a1.5 1.5 0 000 3v-3zm13 3a1.5 1.5 0 000-3v3zm-13 2a1.5 1.5 0 000 3v-3zm13 3a1.5 1.5 0 000-3v3zm-13 2a1.5 1.5 0 000 3v-3zm13 3a1.5 1.5 0 000-3v3zm-13-10h13v-3H10v3zm0 5h13v-3H10v3zm0 5h13v-3H10v3z"/> */}
          {/* <path
          
            fill="#fff"
            
            d="M9.174 24.886c1.049.517 2.623-.863 2.623-1.295l-2.623-3.236 1.967-.647c1.53.647 3.935 1.942 3.935 1.942 1.967-4.53.655-8.414-2.623-11.65-.656.647-1.968 2.589-3.28 3.883-2.185.863-5.377 1.424-5.901 1.942-.656.647 0 3.236.655 4.53.525 1.036 1.53.863 1.968.647.656 1.079 2.23 3.366 3.279 3.884z"
          /> */}
          <path style={{visibility: 'hidden'}} id="closeIcon" fill="#fff" d="M22.414 13.414a2 2 0 10-2.828-2.828L16.5 13.672l-3.086-3.086a2 2 0 10-2.828 2.828l3.086 3.086-3.086 3.086a2 2 0 102.828 2.828l3.086-3.086 3.086 3.086a2 2 0 102.828-2.828L19.328 16.5l3.086-3.086z" clip-rule="evenodd"/>
          {/* <path
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M27.5 20.5c1-4.5 0-11.5-2.5-14M23 20c1-4 0-9.5-2-11.5M19 19c.5-2.5.5-6-1.5-8"
          /> */}
        </svg>
        <Box
          sx={{
            width: 400,
            maxHeight: 500,
            // bg: 'white',
            zIndex: 2,
            position: "absolute",
            left: -450,
            top: "-100%",
            borderRadius: 10,
            bg: "secondaryBlue",
            display: isNewsVisible ? "flex" : "none",
            flexDirection: "column",
            overflow: "hidden",
            color: "white",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: 200,
              bg: "gray",
            }}
          >
            <Img
              style={{ width: "100%", height: "100%" }}
              fluid={heroSeasonNews.image.fluid}
            ></Img>
          </Box>
          <Box
            sx={{
              px: 3,
              py: 3,
            }}
          >
            <Heading
              sx={{
                mb: 2,
              }}
            >
              {heroSeasonNews.title}
            </Heading>
            <Text>{heroSeasonNews.description}</Text>
          </Box>
        </Box>
      </Box>
      {/* VESTI */}
      {/* <Box
                sx={{
                    height: ['50vh','100%'],
                    width: ['100%','30%'],
                    

                }}>
                <Box
                    sx={{
                        height: '50%',
                        width: '100%',
                        bg: '#20314C',
                        padding:'15px',

                        '& .gatsby-image-wrapper': {
                            height: '100%',
                            borderRadius:'10%',
                        }
                    }}>
                    <Img fluid={heroSeasonNews.image.fluid}>
                    
                    </Img>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        bg: '#374E70',
                        height: '50%',
                        width: '100%',
                        padding: '10px',

                    }}>
                    <Heading
                    sx={{
                        fontSize: 6,
                        color: 'white',
                        textAlign: "center",
                    }}>

                    {heroSeasonNews.titleEn}

                </Heading>
                   

                    <Text
                        sx={{
                            fontSize: 5,
                            color:'#C4C4C4',
                            
                        }}>
                             {heroSeasonNews.description}
                    </Text>
                        
                    </Box>

            </Box> */}
    </Box>
  )
}
export default Hero
